import Vue from "vue";
import Router from "vue-router";

import store from '../store/index'

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export const constantRoutes = [
  {
    path: "/login",
    component: () =>
        import ("@/views/login/login"),
    meta: {
        title: "Login"
    },
  },
  {
    path: "/admin/login",
    component: () =>
        import ("@/views/adminLogin/adminLogin"),
    meta: {
        title: "adminLogin"
    },
  },
  {
    path: "/markDetail",
    component: () =>
        import ("@/views/examMarking/markDetail"),
    meta: {
        title: "评阅详情"
    }, 
  },
  {
    path: "/scanUnnormalDetail",
    component: () =>
        import ("@/views/examDetailsEdit/scanUnnormalDetail.vue"),
    meta: {
        title: "异常详情"
    }, 
  },
  {
    path: "/examDetailsEdit",
    name: "examDetailsEdit",
    component: () =>
        import ("@/views/examDetailsEdit/index"),
    hidden: true,
    meta: {
        title: "考试详情"
    },
    children:[
        {
            path: "/examDetailsEdit/templateConfirm",
            name: "templateConfirm",
            component: () =>
                import ("@/views/examDetailsEdit/templateConfirm.vue"),
            meta: {
                title: "模版确认"
            }
        },
        {
            path: "/examDetailsEdit/assignWork",
            name: "assignWork",
            component: () =>
                import ("@/views/examDetailsEdit/assignWork.vue"),
            meta: {
                title: "分配任务"
            }
        },
        {
            path: "/examDetailsEdit/markPaperProcess",
            name: "markPaperProcess",
            component: () =>
                import ("@/views//examDetailsEdit/markPaperProcess.vue"),
            meta: {
                title: "阅卷进度"
            }
        },
        {
            path: "/examDetailsEdit/scoreUpload",
            name: "scoreUpload",
            component: () =>
                import ("@/views/examDetailsEdit/scoreUpload.vue"),
            meta: {
                title: "发布成绩"
            }
        },
        {
            path: "/examDetailsEdit/reviewBlock",
            name: "reviewBlock",
            component: () =>
                import ("@/views/examDetailsEdit/reviewBlock.vue"),
            meta: {
                title: "画批阅块"
            }
        },
        {
            path: "/examDetailsEdit/setSamplingPoint",
            name: "setSamplingPoint",
            component: () =>
                import ("@/views/examDetailsEdit/setSamplingPoint.vue"),
            meta: {
                title: "设采分点"
            }
        },
        {
            path: "/examDetailsEdit/enterStandardAnswer",
            name: "enterStandardAnswer",
            component: () =>
                import ("@/views/examDetailsEdit/enterStandardAnswer.vue"),
            meta: {
                title: "标准答案录入"
            }
        },
        {
            path: "/examDetailsEdit/scanStudentHome",
            name: "scanStudentHome",
            component: () =>
                import ("@/views/examDetailsEdit/scanStudentHome.vue"),
            meta: {
                title: "扫描学生"
            }
        },
        {
            path: "/examDetailsEdit/examEdit",
            name: "examEdit",
            component: () =>
                import ("@/views/examDetailsEdit/examEdit.vue"),
            meta: {
                title: "考场编辑"
            }
        },
        {
            path: "/examDetailsEdit/templateConfirm2",
            name: "templateConfirm2",
            component: () =>
                import ("@/views/examDetailsEdit/templateConfirm2.vue"),
            meta: {
                title: "模版确认"
            }
        },
        {
            path: "/examDetailsEdit/reviewBlockSetting",
            name: "reviewBlockSetting",
            component: () =>
                import ("@/views/examDetailsEdit/reviewBlockSetting.vue"),
            meta: {
                title: "批阅块设置"
            }, 
        },
    ]
  },
  {
    path: "/makeSheetCard",
    component: () =>
        import ("@/views/makeSheetCard/index_ruoyan"),
    meta: {
        title: "答题卡制作"
    }
  },
  {
    path: "/makeSheetCard/preview",
    component: () =>
        import ("@/views/makeSheetCard/preview"),
    meta: {
        title: "答题卡预览"
    }
  },
  {
    path: "/",
    redirect:"/userInfo",
    component: () =>
        import ("@/views/layout/index"),
    meta: {
        title: ""
    },
  },
  {
    path: "/adminInfo",
    redirect: "/adminInfo/homePage",  // 修正重定向路径
    component: () => import("@/views/userInfo/admin"),
    meta: {
      title: ""  // 添加标题（如果需要）
    }
  },
  {
    path: "/adminInfo/homePage",
    component: () => import("@/views/userInfo/adminInfo/index"),
    meta: {
      title: ""  // 添加标题（如果需要）
    },
    children:[
        {
            path: "",  // 默认重定向到子路由
            redirect: "/adminInfo/homePage/content"  // 重定向到 content
        },
        {
            path: "content",
            component: () =>
                import ("@/views/userInfo/adminInfo/content"),
            meta: {
                title: "首页"
            }, 
        },
        {
            path: "userPersion",
            component: () =>
                import ("@/views/userInfo/adminInfo/userPersion"),
            meta: {
                title: "用户管理"
            }, 
        },
        {
            path: "permissionManagement",
            component: () =>
                import ("@/views/userInfo/adminInfo/permissionManagement"),
            meta: {
                title: "角色管理"
            }, 
        }
        
    ]
  },
  {
    path: "/userInfo",
    redirect:"/userInfo/personInfo",
    component: () =>
        import ("@/views/userInfo/index"),
    meta: {
        title: "个人中心"
    },
    children:[
        {
            path: "personInfo",
            component: () =>
                import ("@/views/userInfo/personInfo"),
            meta: {
                title: "个人信息"
            }, 
        },
        {
            path: "schoolGradeSubject",
            component: () =>
                import ("@/views/userInfo/schoolGradeSubject"),
            meta: {
                title: "校年级科目"
            }, 
        },
        {
            path: "schoolGradeTerm",
            component: () =>
                import ("@/views/userInfo/schoolGradeTerm"),
            meta: {
                title: "校学年学期"
            }, 
        },
        {
            path: "userManagement",
            component: () =>
                import ("@/views/userInfo/userManagement"),
            meta: {
                title: "用户管理"
            }, 
        },
        {
            path: "schoolClassStudent",
            component: () =>
                import ("@/views/userInfo/schoolClassStudent"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        {
            path: "schoolTeachersList",
            component: () =>
                import ("@/views/userInfo/schoolTeachersList"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        {
            path: "schoolManaPerson",
            component: () =>
                import ("@/views/userInfo/schoolManaPerson"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        
    ]
  },
  {
    path: "/examMarking",
    redirect:"/examMarking/markingTasks",
    component: () =>
        import ("@/views/examMarking/index"),
    hidden: true,
    meta: {
        title: "考试阅卷"
    },
    children: [
        {
            path: "markingTasks",
            component: () =>
                import ("@/views/examMarking/markingTasks"),
            meta: {
                title: "阅卷任务"
            }, 
        },
        {
            path: "examDoc",
            component: () =>
                import ("@/views/examMarking/examDoc"),
            meta: {
                title: "操作文档"
            }, 
        },
        {
            path: "examMonitor",
            component: () =>
                import ("@/views/examMarking/examMonitor"),
            meta: {
                title: "问卷监控"
            }, 
        },
        {
            path: "myAnwserCard",
            component: () =>
                import ("@/views/examMarking/myAnwserCard_ruoyan"),
            meta: {
                title: "我的答题卡",
            }, 
        },
        {
            path: "myExamList",
            component: () =>
                import ("@/views/examMarking/myExamList"),
            meta: {
                title: "我的考试"
            }, 
        },
        {
            path: "analysisMana",
            component: () =>
                import ("@/views/examMarking/analysisMana"),
            meta: {
                title: "分析管理"
            }, 
        },
    ]
  },
  {
        path: "/analysisReportList",
        component: () =>
            import ("@/views/layout/index"),
        meta: {
            title: "分析报告"
        },
        children:[{
            path: "list",
            component: () =>
                import ("@/views/examAnalyReport/list"),
            meta: {
                title: "分析报告"
            }  
        }] 
    },
  {
    path: "/analysisReport",
    component: () =>
        import ("@/views/examAnalyReport/index"),
    hidden: true,
    meta: {
        title: "分析报告"
    },
    children:[
        {
            path: "overviewCourse",
            name: "overviewCourse",
            component: () =>
                import ("@/views/examAnalyReport/overviewCourse"),
            meta: {
                title: "数据总览"
            }  
        },
        {
            path: "transcriptSimpleCourse",
            name:"transcriptSimpleCourse",
            redirect:"/analysisReport/schoolReport",
            component: () =>
                import ("@/views/examAnalyReport/transcriptSimpleCourse"),
            meta: {
                title: "成绩分析"
            }

        },
        {
            path: "schoolReport",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/schoolReport"),
            meta: {
                title: "成绩单"
            }
        },
        {
            path: "horizontalDistribution",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/horizontalDistribution"),
            meta: {
                title: "水平分布"
            }
          },
          {
            path: "classComparison",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/classComparison"),
            meta: {
                title: "班级对比"
            }
          },
          {
            path: "groupAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/groupAnalysis"),
            meta: {
                title: "G组分析"
            }
          },
          {
            path: "quartileAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/quartileAnalysis"),
            meta: {
                title: "四分位分析"
            }
          },
          {
            path: "criticalLifeAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/criticalLifeAnalysis"),
            meta: {
                title: "临界生分析"
            }
          },
          {
            path: "examPaperAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/examPaperAnalysis"),
            meta: {
                title: "试卷分析"
            }
          },
          {
            path: "commentaryAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/commentaryAnalysis"),
            meta: {
                title: "讲评分析"
            }
          },
          {
            path: "studentAnalysis",
            component: () =>
                import ("@/views/examAnalyReport/components/transcriptSimpleCourse/studentAnalysis"),
            meta: {
                title: "学生分析"
            }
          },
        {
            path: "analysisScoreLadder",
            name: "analysisScoreLadder",
            component: () =>
                import ("@/views/examAnalyReport/analysisScoreLadder"),
            meta: {
                title: "分数段统计"
            }  
        },
        {
            path: "analysisAverageScore",
            name: "analysisAverageScore",
            component: () =>
                import ("@/views/examAnalyReport/analysisAverageScore"),
            meta: {
                title: "平均分分析"
            }  
        },
        {
            path: "analysisComprehensive",
            name: "analysisComprehensive",
            component: () =>
                import ("@/views/examAnalyReport/analysisComprehensive"),
            meta: {
                title: "综合指标分析"
            }  
        },
        
    ]
  },
  {
    path: "/knowTopic",
    component: () =>
        import ("@/views/layout/index"),
    meta: {
        title: "校本题库"
    },
    children:[{
        path: "list",
        component: () =>
            import ("@/views/schoolBasedQuestionBank/list"),
        meta: {
            title: "校本题库"
        }  
    }] 
  },
  {
    path: "/makeSheetCard",
    component: () =>
        import ("@/views/makeSheetCard/index_ruoyan"),
    hidden: true,
    meta: {
        title: "制卡"
    },
  },
  {
    path: "/examAnalysisMana",
    component: () =>
        import ("@/views/examAnalysisMana/index"),
    hidden: true,
    meta: {
        title: "分析管理详情"
    },
    children: [
      {
        path: "examSetting",
        component: () =>
            import ("@/views/examAnalysisMana/examSetting"),
        meta: {
            title: "考试设置"
        }
      },
      {
        path: "smallQuestionsEdit",
        component: () =>
            import ("@/views/examAnalysisMana/smallQuestionsEdit"),
        meta: {
            title: "模版管理"
        }
      },
      {
        path: "questionsGroupMana",
        component: () =>
            import ("@/views/examAnalysisMana/questionsGroupMana"),
        meta: {
            title: "题目分组"
        }
      },
      {
        path: "benchMarkExamPaper",
        component: () =>
            import ("@/views/examAnalysisMana/benchMarkExamPaper"),
        meta: {
            title: "标杆试卷"
        }
      },
      {
        path: "examVenuesStudentList",
        component: () =>
            import ("@/views/examAnalysisMana/examVenuesStudentList"),
        meta: {
            title: "考场名单"
        }
      },
      {
        path: "smallQuestionScore",
        component: () =>
            import ("@/views/examAnalysisMana/smallQuestionScore"),
        meta: {
            title: "小题得分"
        }
      },
      {
        path: "correctionGrades",
        component: () =>
            import ("@/views/examAnalysisMana/correctionGrades"),
        meta: {
            title: "纠错成绩"
        }
      },
      {
        path: "summaryOfGrade",
        component: () =>
            import ("@/views/examAnalysisMana/summaryOfGrade"),
        meta: {
            title: "成绩汇总"
        }
      }
    ]
  },
  {
    path: "/multidetails",
    component: () =>
        import ("@/views/multidetails/index"),
    hidden: true,
    meta: {
        title: "分析管理详情"
    },
    children: [
      {
        path: "examMultiSetting",
        component: () =>
            import ("@/views/multidetails/examMultiSetting"),
        meta: {
            title: "考试设置"
        }
      },
      {
        path: "examRoomList",
        component: () =>
            import ("@/views/multidetails/examRoomList"),
        meta: {
            title: "考场名单"
        }
      },
      {
        path: "summaryGrades",
        component: () =>
            import ("@/views/multidetails/summaryGrades"),
        meta: {
            title: "成绩汇总"
        }
      },
    ]
  },
  {
    path: "/analyzeManageSystemSettings",
    component: () =>
        import ("@/views/analyzeManageSystemSettings/index"),
    hidden: true,
    meta: {
        title: "分析管理详情"
    },
    children: [
      {
        path: "mangeSetting",
        component: () =>
            import ("@/views/analyzeManageSystemSettings/mangeSetting"),
        meta: {
            title: "考试设置"
        }
      }
    ]
  },
]

const createRouter = () =>
    new Router({
        mode:"hash",
        routes: constantRoutes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return {
                    x: 0,
                    y: 0
                }
            }
        },
    });

const router = createRouter();
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}
// router.beforeEach((to, from, next) => {
//     if (to.path == '/knowTopic/list') {
//         next(); // 直接放行路径
//     }
// })
// router.beforeEach((to, from, next) => {
//     if (to.meta.keep === from.meta.keep) { // 进入detail页面 缓存当前list页面
//         store.commit('addAlive', from.name)
//     } else {
//         store.commit('clearAlive')
//     }
//     next()
// })


export default router;