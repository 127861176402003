import { deleteRequest, get, post,put } from '../common/http';
import base from '../common/base';

// import '../common/base'
const user = {
  //用户名密码登录
  login(data) {
    return post( base.prefix+ `/api/v1/auth/login`, data)
  },
  //退出接口
  logout(data) {
    return post( base.prefix+ `/api/v1/auth/logout`, data)
  },
  login1(data) {
    return post( base.prefix1+ `/adminApi/v1/auth/login`, data,true)
  },
  //邮箱登录
  emailLogin(data) {
    return post( base.prefix+ `/api/v1/auth/emailLogin`, data)
  },
  //登录信息绑定邮箱
  sendBindEmailCode(data){
    return post(base.prefix + '/api/v1/email/sendBindEmailCode', data)
  },
  //获取用户信息
  getUserInfo() {
    return get(base.prefix + '/api/v1/teach/user/getCurrentUserInfo')
  }, 
  // 修改pw
  changeMinePassword(data) {
    return post(base.prefix + '/api/v1/teach/user/updatePassword', data)
  },
  //重置密码
  resetUserPassword(data) {
    return post(base.prefix + '/api/v1/teach/user/resetPassword', data)
  },
  //邮箱验证码
  sendLoginEmailCode(data) {
    return post(base.prefix + '/api/v1/email/sendLoginEmailCode', data)
  },
  //邮箱找回用户密码
  updateEmailCodePassword(data) {
    return post(base.prefix + '/api/v1/teach/user/updateEmailCodePassword', data)
  },
  //找回密码密码修改
  sendReSetPwdEmailCode(data) {
    return post(base.prefix + '/api/v1/email/sendReSetPwdEmailCode', data)
  },
  //找回密码邮箱验证码校验
  checkEmailCode(data) {
    return post(base.prefix + '/api/v1/teach/user/checkEmailCode', data)
  },
  callBackCode(data) {
    return get(base.prefix + 'api/v1/callBack?code=ST-8260762-bIy3PwySukykmjreehBy0rX4IUdc6n5z', data)
  },
  // 管理平台获取学校数量
  getStatisticalSchoolNumber(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/getStatisticalSchoolNumber',data,true)
  }, 
  // 管理平台获取省
  getProvinceList(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/getProvinceList',data,true)
  }, 
  // 管理平台获取市
  getAreaList(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/getAreaList',data,true)
  }, 
  getStatisticalSchoolInfo(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/getStatisticalSchoolInfo',data,true)
  }, 
  createSchoolInfo(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/createSchoolInfo',data,true)
  }, 
  adminUpdateCloseSchoolUserStatus(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/adminUpdateCloseSchoolUserStatus',data,true)
  }, 
  UpdateCloseSchoolUserStatus(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/update/'+data.id,data,true)
  }, 
  adminOpenUpdateSchoolUserStatus(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/adminOpenUpdateSchoolUserStatus',data,true)
  }, 
  adminAddSchoolManyGradeInfo(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/gradeRelation/adminAddSchoolManyGradeInfo/'+data.schoolTenantId,data,true)
  }, 
  adminQuerySchoolAllGradeInfoList(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/gradeRelation/adminQuerySchoolAllGradeInfoList/'+data.schoolTenantId,data,true)
  }, 
  resetSchoolAdminPwd(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/resetSchoolAdminPwd',data,true)
  }, 
  getAllListGrade(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/grade/getAllList',data,true)
  }, 
  querySchoolAdminUserAndPwd(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/querySchoolAdminUserAndPwd/'+data.userId,data,true)
  }, 
  getAllListCourse(data) {
    return get(base.prefix1 + '/adminApi/v1/admin/school/course/getAllList',data,true)
  }, 
  getuserInfos(data) {
    return get(base.prefix1 + '/adminApi/v1/sys/users',data,true)
  }, 
  getuserInfosList(data) {
    return get(base.prefix1 + '/adminApi/v1/sys/userList',data,true)
  }, 
  getRoles(data) {
    return get(base.prefix1 + '/adminApi/v1/sys/roles',data,true)
  }, 
  getRolesList(data) {
    return get(base.prefix1 + '/adminApi/v1/sys/roleList',data,true)
  }, 
  addCourseInfo(data) {
    return post(base.prefix1 + '/adminApi/v1/admin/school/course/courses',data,true)
  }, 
  addUsers(data) {
    return post(base.prefix1 + '/adminApi/v1/sys/users',data,true)
  }, 
  addRoles(data) {
    return post(base.prefix1 + '/adminApi/v1/sys/roles',data,true)
  }, 
  editUsers(data) {
    return put(base.prefix1 + '/adminApi/v1/sys/users/'+data.id,data,true)
  }, 
  editRoles(data) {
    return put(base.prefix1 + '/adminApi/v1/sys/roles/'+data.id,data,true)
  }, 
  deleteCourseInfo(data) {
    return deleteRequest(base.prefix1 + '/adminApi/v1/admin/school/course/deleteByIds',data,"arr",true)
  }, 
  deleteUsers(data) {
    return deleteRequest(base.prefix1 + '/adminApi/v1/sys/users',data,"arr",true)
  }, 
  deleteRoles(data) {
    return deleteRequest(base.prefix1 + '/adminApi/v1/sys/roles',data,"arr",true)
  }, 
}

export default user