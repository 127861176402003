<template>
  <div id="app" ref="app">
    <router-view />
  </div>
</template>

<script>
import DevicePixelRatio from '@/utils/DevicePixelRatio.js';
export default {
  name: 'App',
  components: {
    
  },
  mounted() {
    new DevicePixelRatio().init();
  },
}
</script>

<style>
#app{
  height: 100%;
  width:100%;
}
</style>
